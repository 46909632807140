<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from "vuex";

export default {
  // store,
  name: "App",
  // components: {
  //   Configurator,
  //   // Canvas3d: this.$canvas3d,
  // },
  // data() {
  //   return {
  //     // appDark: true,
  //   };
  // },
  mounted: async function () {
    // eslint-disable-next-line no-undef
    gtag('config', this.gtagId, {
      'custom_map': {
        'dimension1': 'aste',
        'dimension2': 'coloreMetalloAste',
        'dimension3': 'finitura',
        'dimension4': 'asianFitting',
        'dimension5': 'coloreFrontale',
        'dimension6': 'misura',
        'dimension7': 'finituraAste',
        'dimension8': 'nomePersonalizzato',
        'dimension9': 'coloreNomePersonalizzato',
      }
    })
  },
  // methods: {
  //   ...mapMutations("configurator", ["setFrontal"]),
  //   ...mapActions("configurator/frontali", ["getItems"]),
  // },
  computed: {
    ...mapState(["gtagId"]),
  },
  // // watch: {
  // //   items(newVal) {
  // //     console.log(newVal, "ccccccccccccccccccccccccccccccccc");
  // //     // this.setFrontal(newVal[15]);
  // //     // this.$store.state.configurator.frontale = newVal[0];
  // //   },
  // // },
};
</script>


<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  height: 100dvh;
}
</style>
