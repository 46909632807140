import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";

/* eslint-disable no-console */
var pack = require("../package.json");

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register("".concat(process.env.VUE_APP_BASE_ROUTE).concat(pack.name, "/v").concat(pack.version, "/service-worker.js"), {
    ready: function ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
    },
    registered: function registered() {
      console.log('Service worker has been registered.');
    },
    cached: function cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound: function updatefound() {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches["delete"](cacheName);
        });
      });
      console.log('New content is downloading.');
    },
    updated: function updated() {
      console.log('New content is available; please refresh.');
    },
    offline: function offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error: function error(_error) {
      console.error('Error during service worker registration:', _error);
    }
  });
}